// src/LinksPage.js
import React from 'react';
import '../styles/LinksPage.css';
import logo from '../assets/images/logoFree.png';
import instaLogo from '../assets/images/instaLogo.png';
import gmailLogo from '../assets/images/gmailLogo.png';
import websiteLogo from '../assets/images/websiteLogo.png';
import logoVinted from '../assets/images/logoVinted.png';

function LinksPage() {
    const links = [
        {
            url: 'https://www.instagram.com/maf_tuft/',
            text: '@maf_tuft',
            logo: instaLogo,
            target: '_blank'
        },
        {
            url: 'mailto:lucasmafrica57@gmail.com',
            text: 'lucasmafrica57@gmail.com',
            logo: gmailLogo,
            target: ''
        },
        {
            url: 'https://maf-industries.lucas-mafrica.fr/',
            text: 'Ma vitrine',
            logo: websiteLogo,
            target: ''
        },
        {
            url: 'https://www.vinted.fr/member/82496574',
            text: 'Vinted',
            logo: logoVinted,
            target: '_blank'
        }
        // Ajoutez autant de liens que nécessaire
    ];

    return (
        <div className='links-page'>
            <div className='links-header-logo'>
                <img src={logo} alt='Logo Principal' />
                <h1>MAF INDUSTRIES</h1>
            </div>
            <div className='links-list'>
                {links.map((link, index) => (
                    <a
                        key={index}
                        href={link.url}
                        target={link.target}
                        className='link-item'
                    >
                        <img src={link.logo} alt={`Logo ${index + 1}`} />
                        <span>{link.text}</span>
                    </a>
                ))}
            </div>
        </div>
    );
}

export default LinksPage;
