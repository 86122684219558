// src/components/ProductsSection.js
import React, { useEffect, useRef } from 'react';
import '../styles/ProductsSection.css';
import lavaLamp from '../assets/images/rugs/lavalamp.png';
import lavaLamp2 from '../assets/images/rugs/lavalamp2.jpeg';
import snoopy1 from '../assets/images/rugs/snoopy1.jpg';
import snoopy2 from '../assets/images/rugs/snoopy2.jpg';
import roses1 from '../assets/images/rugs/roses1.jpeg';
import roses2 from '../assets/images/rugs/roses2.jpeg';
import mauve from '../assets/images/rugs/mauve.png';
import necron from '../assets/images/rugs/necron.png';
import daruma from '../assets/images/rugs/daruma.png';
import daruma2 from '../assets/images/rugs/daruma2.jpeg';
import daruma3 from '../assets/images/rugs/daruma3.jpeg';
import clavier from '../assets/images/rugs/clavier.png';
import clavier2 from '../assets/images/rugs/clavier2.jpeg';
import totoro from '../assets/images/rugs/totoro.png';
import bouftou from '../assets/images/rugs/bouftou.jpg';
import cactus from '../assets/images/rugs/cactus.jpg';
import cactus2 from '../assets/images/rugs/cactus2.jpeg';
import cactus3 from '../assets/images/rugs/cactus3.jpeg';
import socut from '../assets/images/rugs/socut.jpeg';
import socut2 from '../assets/images/rugs/socut2.jpeg';
import flowers1 from '../assets/images/rugs/flowers1.jpg';
import flowers2 from '../assets/images/rugs/flowers2.jpg';
import flowers3 from '../assets/images/rugs/flowers3.jpg';
import chat1 from '../assets/images/rugs/chat1.jpeg';
import chat2 from '../assets/images/rugs/chat2.jpeg';
import chat3 from '../assets/images/rugs/chat3.jpeg';
import sun1 from '../assets/images/rugs/sun1.png';
import sun2 from '../assets/images/rugs/sun2.png';
import sun3 from '../assets/images/rugs/sun3.png';
import catMoon1 from '../assets/images/rugs/catMoon1.png';
import catMoon2 from '../assets/images/rugs/catMoon2.png';
import catMoon3 from '../assets/images/rugs/catMoon3.png';
import { ProductCard } from 'maf-react-components';

const ProductsSection = () => {
    const parallaxContainerRef = useRef(null);
    const productsSectionRef = useRef(null);

    useEffect(() => {
        if (parallaxContainerRef.current && productsSectionRef.current) {
            const productsHeight = productsSectionRef.current.offsetHeight;
            parallaxContainerRef.current.style.height = `${productsHeight}px`;
        }
    }, []);

    const products = [
        {
            title: 'Lampe à Lave',
            price: '80,00',
            imageUrl: [lavaLamp, lavaLamp2],
            width: '77',
            height: '33',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'L’œil mystique',
            price: '40,00',
            imageUrl: mauve,
            width: '75',
            height: '35',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'Symbole Necron',
            price: '60,00',
            imageUrl: necron,
            width: '70',
            height: '35',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'Daruma',
            price: '40,00',
            imageUrl: [daruma, daruma2, daruma3],
            width: '36',
            height: '36',
            isSold: false,
            linkCart:
                'https://www.vinted.fr/items/4699767220-tapis-daruma-fait-mains'
        },
        {
            title: 'Tapis de Clavier',
            price: '30,00',
            imageUrl: [clavier, clavier2],
            width: '36',
            height: '16',
            isSold: false,
            linkCart:
                'https://www.vinted.fr/items/4699790300-tapis-de-clavier-fait-mains'
        },
        {
            title: 'Totoro',
            price: '50,00',
            imageUrl: totoro,
            width: '70',
            height: '40',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'Bouftou',
            price: '55,00',
            imageUrl: bouftou,
            width: '70',
            height: '70',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'Cactus Paysage',
            price: '80,00',
            imageUrl: [cactus, cactus2, cactus3],
            width: '53',
            height: '46',
            isSold: false,
            linkCart:
                'https://www.vinted.fr/items/5652390132-tapis-tufting-paysage-desertique'
        },
        {
            title: 'Logo SoCut',
            price: '0,00',
            imageUrl: [socut, socut2],
            width: '70',
            height: '55',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'Parterre floral',
            price: '0,00',
            imageUrl: [flowers1, flowers2, flowers3],
            width: '70',
            height: '70',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'Rocket cat',
            price: '200,00',
            imageUrl: [chat1, chat2, chat3],
            width: '75',
            height: '75',
            isSold: false,
            linkCart:
                'https://www.vinted.fr/items/5562097531-tapis-fait-mains-tufting-chat'
        },
        {
            title: 'Snoopy',
            price: '70,00',
            imageUrl: [snoopy1, snoopy2],
            width: '70',
            height: '40',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'Parterre de roses',
            price: '80,00',
            imageUrl: [roses1, roses2],
            width: '60',
            height: '60',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'Lune et Chat',
            price: '70,00',
            imageUrl: [catMoon1, catMoon2, catMoon3],
            width: '25',
            height: '25',
            isSold: false,
            linkCart:
                'https://www.vinted.fr/items/5695107700-tapis-miroir-chat-lunaire-concept-unique'
        },
        {
            title: 'Soleil Miroir',
            price: '70,00',
            imageUrl: [sun1, sun2, sun3],
            width: '40',
            height: '40',
            isSold: true,
            linkCart: ''
        }
    ];
    return (
        <div className='big-container'>
            <div ref={productsSectionRef} className='products-section'>
                {products.map((product, index) => (
                    <ProductCard key={index} {...product} />
                ))}
            </div>
        </div>
    );
};

export default ProductsSection;
